import isEmpty from "lodash/isEmpty";
import queryString from "query-string";

import { QueryParameters } from "../interface";

export const APP_LINK = "https://cyphergame.io";
export const APP_NAME = `Try This Hour's Cypher Puzzle ${APP_LINK}`;
export const GAME_SESSION_KEY = 'lastGameState';

export const API_CONFIG = {
  baseUrl: `${process.env.REACT_APP_BASE_URL}`,
  path: {
    login: "auth/login",
  },
};

export const getUrl = (url: string, params: QueryParameters = {}): string => {
  if (!url.includes("https")) {
    let urlString = `${API_CONFIG.baseUrl}/${url}`;
    if (params && !isEmpty(params)) {
      urlString += `?${queryString.stringify(params)}`;
    }
    return urlString;
  }

  return url;
};

export const RANDOM_STRING =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

export const USERNAME_REGEX = /^[a-z0-9_äöüß]*$/;

export const EMAIL_REGX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
