import { useState } from "react";
import { Link } from "react-router-dom";
import puzzleText from "../../assets/svg/homeLogoText.svg";
import { Tooltip } from "react-tooltip";
import { GameResultStateType } from "shared/interface/game";
import { formatTime } from "../../shared/util/date";

const Complete = (props: { gameResultState: GameResultStateType }) => {
  const [tooltipContent, setTooltipContent] = useState("Copy to clipboard");
  const handleClick = () => {
    const linkToCopy =
      `Solved With: #REMAINING_TIME# seconds remaining.\nTry it: bit.ly/cyphergame`.replace(
        "#REMAINING_TIME#",
        formatTime(props.gameResultState.remainingTime)
      );
    navigator.clipboard
      .writeText(linkToCopy)
      .then(() => {
        setTooltipContent("Copied!");
        setTimeout(() => {
          setTooltipContent("Copy to clipboard");
        }, 3000);
      })
      .catch((error) => {
        setTooltipContent("Copy to clipboard");
      });
  };

  return (
    <section className="screenContainer">
      <div className="headerContainer">
        <img
          className="headerWrapper lg:hidden max-lg:pt-20 max-sm:pt-12"
          src={puzzleText}
          alt="puzzleText"
        />

        <div className="boxContainer max-sm:mb-10 mb-[84px]">
          {props.gameResultState.activeWordChar.split("").map((char, index) => (
            <div key={index} className="boxWrapper">
              <div className="beginBox border-secondary">{char}</div>
            </div>
          ))}
        </div>

        <p className="completePara">
          Congrats! You have solved the Cypher! <br />
          You solved it with {formatTime(
            props.gameResultState.remainingTime
          )}{" "}
          seconds remaining!
        </p>
        <div className="buttonContainer responsiveButton max-md:flex-col lg:gap-11 max-md:pt-16">
          <div
            style={{
              position: "relative",
              fontSize: "20px",
              fontWeight: 600,
            }}
          >
            <button
              className="primaryButton"
              onClick={handleClick}
              data-tooltip-id="my-tooltip-inline"
              data-tooltip-content={tooltipContent}
            >
              Share
            </button>
            <Tooltip
              id="my-tooltip-inline"
              style={{
                backgroundColor: "#fff",
                color: "black",
              }}
            />
          </div>

          <Link to="/" aria-current="page">
            <button className="primaryButton bg-none border-white border-4">
              Close
            </button>
          </Link>
        </div>
      </div>
    </section>
  );
};
export default Complete;
