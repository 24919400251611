import { Link } from "react-router-dom";
import logo from "../../assets/image/logo.png";
import { useState } from "react";
import shareIcon from "../../assets/svg/share.svg";
import { APP_LINK } from "../../shared/constants/constant";

const Button = () => {
  const [tooltipContent, setTooltipContent] = useState("Copy to clipboard");

  const handleClick = () => {
    navigator?.clipboard
      .writeText(APP_LINK)
      .then(() => {
        setTooltipContent("Copied");
        setTimeout(() => {
          setTooltipContent("Copy to clipboard");
        }, 5000);
      })
      .catch((error) => {
        console.error("Failed to copy:", error);
      });
  };

  return (
    <div
      style={{
        position: "relative",
        backgroundColor: "white",
        color: "black",
        fontSize: "20px",
        fontWeight: 600,
      }}
    >
      <button
        className="navButton"
        onClick={handleClick}
        title={tooltipContent}
      >
        <img className="icon" src={shareIcon} alt="shareIcon" />
        Share
      </button>
    </div>
  );
};

const Navbar = () => {
  return (
    <nav className="navContainer">
      <Link to="/" aria-current="page">
        <img className="max-md:w-56" src={logo} alt="logo" />
      </Link>
      <div className="navWrapper">
        <Link to="/play" className="navPlay" aria-current="page">
          How to play
        </Link>
        <Button />
      </div>
    </nav>
  );
};
export default Navbar;
