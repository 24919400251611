import { useCallback, useEffect, useState } from "react";

import { ChangeArrayOptions, SolvePropsType } from "shared/interface/game";

import { formatTime } from "../../shared/util/date";
import { group, nextFiveCharacters } from "../../shared/util/game";
import { CharacterBox } from "../GameWidget/CharacterBox";
import { RANDOM_WORDS } from "assets/words/all-files";

const Solve = ({ gameResultState, setGameResultState }: SolvePropsType) => {
  const [startGame, setStartGame] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [timeStopped, setTimeStopped] = useState(false);
  const [wordBank, setWordBank] = useState<{
    hour: number | null;
    words: string[];
  }>({
    hour: null,
    words: [],
  });
  console.log(timeStopped);
  const [activeGameCharacters, setActiveGameCharacters] = useState<string[]>([
    "",
    "",
    "",
    "",
    "",
  ]);
  const [showHighlight, setShowHighlight] = useState(false);
  const [currentWordState, setCurrentWordState] = useState<string[]>([
    "",
    "",
    "",
    "",
    "",
  ]);
  const [alphabetListGroups, setAlphabetListGroups] = useState<group[]>([]);
  const [timeLeft, setTimeLeft] = useState<number>(-1);
  // 0 = NOT STARTED, 1 = RUNNING, -1 PAUSED
  const [timerStatus, setTimerStatus] = useState(0);

  useEffect(() => {
    if (!startGame && gameResultState.activeWordChar) {
      if (currentWordState[0] === "") {
        const nextCharacters = nextFiveCharacters(
          gameResultState.activeWordChar.split("")
        );
        setAlphabetListGroups(nextCharacters.nextCharacters);
        setCurrentWordState(nextCharacters.randomSelection || []);
      }
      if (activeGameCharacters.join("") !== gameResultState.activeWordChar) {
        const nextCharacters = nextFiveCharacters(
          gameResultState.activeWordChar.split("")
        );
        setAlphabetListGroups(nextCharacters.nextCharacters);
        setActiveGameCharacters(
          (gameResultState.activeWordChar ?? "").split("")
        );
        // setAlphabetListGroups(generateGroups(activeGameCharacters));
      }

      if (!startGame && timeLeft === -1) {
        setTimeLeft(gameResultState?.remainingTime);
      }
    }
  }, [
    startGame,
    gameResultState?.activeWordChar,
    gameResultState?.remainingTime,
    gameResultState?.finishedWordChar,
  ]);

  const startTimer = () => {
    if (timerStatus > 0 || timeLeft <= 0) {
      return;
    }

    if (timerStatus <= 0) {
      setTimerStatus(1);
    }
    setShowHighlight(false);
  };

  const handleWordBankClick = () => {
    setTimeStopped(true);
    // clearInterval(timer);
    setShowPopup(true);
  };

  const onChangeArrow = (args: ChangeArrayOptions) => {
    if (!startGame && timeLeft <= 0) {
      return;
    }
    startTimer();
    if (
      alphabetListGroups[args.groupId] &&
      alphabetListGroups[args.groupId][args.newIndex]
    ) {
      setCurrentWordState((prevState) => {
        const currentState = [...prevState];
        currentState[args.groupId] =
          alphabetListGroups[args.groupId][args.newIndex];
        return currentState;
      });
    }
  };

  const updateTimerState = useCallback(
    (submit?: boolean) => {
      if (timeLeft < 0) {
        return;
      }
      if (!timeLeft && !gameResultState.remainingTime) {
        return;
      }
      const completedWord = currentWordState.join("");
      const isWin = completedWord === (gameResultState?.activeWordChar || "");

      let status = "pending";

      if (timeLeft <= 0) {
        status = "done";
      } else if (isWin && submit) {
        status = "done";
      }

      setGameResultState({
        date: gameResultState.date,
        hour: gameResultState.hour,
        remainingTime: timeLeft,
        status,
        finishedWordChar: completedWord,
        activeWordChar: gameResultState?.activeWordChar ?? "",
        isWin: completedWord === (gameResultState?.activeWordChar || ""),
      });
    },
    [currentWordState, timeLeft, gameResultState?.activeWordChar, showHighlight]
  );

  const publishGameResult = useCallback(
    (submit?: boolean) => {
      const completedWord = currentWordState.join("");
      const isWin = completedWord === (gameResultState?.activeWordChar || "");

      if (submit && !isWin && timeLeft) {
        setShowHighlight(true);
        return;
      }

      updateTimerState(submit);
    },
    [
      timeLeft,
      showHighlight,
      currentWordState,
      gameResultState?.activeWordChar,
      updateTimerState,
    ]
  );

  useEffect(() => {
    if (timeLeft === 0 && timerStatus) {
      setTimerStatus(0);
      publishGameResult();
    } else {
      updateTimerState();
    }
  }, [timeLeft, timerStatus]);

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     if (timerStatus) {
  //       setTimeLeft((prevTime) => {
  //         if (prevTime === 0) {
  //           clearInterval(timer);
  //           return 0;
  //         }
  //         if (startGame && timerStatus > 0 && prevTime > 0) {
  //           return prevTime - 1;
  //         }
  //         return prevTime;
  //       });
  //     }
  //   }, 1000);

  //   return () => clearInterval(timer);
  // }, [startGame, timerStatus]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (timerStatus && !showPopup) {
        // Only decrement time if timer is running and popup is not shown
        setTimeLeft((prevTime) => {
          if (prevTime === 0) {
            clearInterval(timer);
            return 0;
          }
          if (startGame && timerStatus > 0 && prevTime > 0) {
            return prevTime - 1;
          }
          return prevTime;
        });
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [startGame, timerStatus, showPopup]);

  const submitGame = useCallback(() => {
    setTimerStatus(-1);
    publishGameResult(true);
  }, [publishGameResult, setTimerStatus]);

  const startGameClick = useCallback(() => {
    setTimerStatus(1);
    setStartGame(true);
    updateTimerState();
  }, [updateTimerState, setTimerStatus]);

  const shuffleArray = (arr: string[]) => {
    const newArray = arr.slice();
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
  };

  const handleGetWords = () => {
    const data = JSON.parse(localStorage.getItem("lastGameState") as string);
    const words = RANDOM_WORDS.filter(
      (item) => item !== data.activeWordChar.toLowerCase()
    );
    let newArr = [];
    for (let i = 0; i < 7; i++) {
      let randomIndex = Math.floor(Math.random() * words.length);
      newArr.push(words[randomIndex]);
    }
    newArr.push(data.activeWordChar);
    const todaysWords = shuffleArray(
      newArr.map((word: any) => word.toUpperCase())
    );

    setWordBank({
      hour: data.hour,
      words: todaysWords,
    });
  };
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("lastGameState") as string);
    if (data.hour !== wordBank.hour) {
      handleGetWords();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="screenContainerPlay screenHeightHub">
      <div className="gameContainer">
        <div aria-current="page" onClick={() => setShowPopup(true)}>
          <h1
            className="headerWrapper solveHeader "
            onClick={handleWordBankClick}
          >
            Word Bank
          </h1>
        </div>
        {showPopup && (
          <div
            className={`popWrapper ${
              showPopup ? "opacity:1 z-10" : "hideOpacity:0"
            }`}
          >
            <div className="flex justify-center flex-col items-center">
              <h1 className=" text-mediumXl font-normal py-6 max-md:text-largeSm">
                Word Bank:
              </h1>
              <div className="wordHeader">
                <div className="wordWrapper">
                  {wordBank.words.map((word, index) => (
                    <div key={index} className={`wordText`}>
                      {word}
                      <div className="inner" />
                    </div>
                  ))}
                </div>

                <div onClick={() => setShowPopup(false)}>
                  <button className="secondaryButton wordButton">Close</button>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="boxContainer relative">
          {alphabetListGroups.length &&
            activeGameCharacters.map((char: string, index: number) => (
              <CharacterBox
                onChangeArrow={onChangeArrow}
                key={index}
                running={startGame}
                showHighlight={showHighlight}
                groupId={index}
                groupAlphabets={alphabetListGroups[index]}
                selectedCharacter={currentWordState[index]}
                rightCharacter={char}
              />
            ))}
        </div>
        <p className="timeRemaining">Time Remaining</p>
        <p className="solveTime">{formatTime(timeLeft)}</p>
        {startGame && (
          <button className="transparentButton lg:mt-12" onClick={submitGame}>
            Submit
          </button>
        )}
        {!startGame && (
          <button
            className="primaryButton lg:mt-12 font-bold"
            onClick={startGameClick}
          >
            Start
          </button>
        )}
      </div>
    </section>
  );
};
export default Solve;
