import { useState } from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { GameResultStateType } from "shared/interface/game";
import { formatTime } from "../../shared/util/date";
// import { APP_LINK } from "shared/constants/constant";

const Fail = ({
  gameResultState,
}: {
  gameResultState: GameResultStateType;
}) => {
  const [tooltipContent, setTooltipContent] = useState("Copy to clipboard");

  const handleClick = () => {
    const linkToCopy = ` Unsolved in 1 Minutes.\nTry It: bit.ly/cyphergame`;
    navigator.clipboard
      .writeText(linkToCopy)
      .then(() => {
        setTooltipContent("Copied!");
        setTimeout(() => {
          setTooltipContent("Copy to clipboard");
        }, 5000);
      })
      .catch((error) => {
        setTooltipContent("Copy to clipboard");
      });
  };

  return (
    <section className="screenContainer">
      <div className="headerContainer">
        <h3 className="solution failTitle">Solution:</h3>
        <div className="failBox">
          {gameResultState.activeWordChar.split("").map((char, index) => (
            <div key={index} className="boxWrapper">
              <div className="beginBox border-secondary">{char}</div>
            </div>
          ))}
        </div>

        <p className="timeRemaining failRemainingTime">Time Remaining</p>
        <p className="failSubtitle">
          {formatTime(gameResultState.remainingTime)}
        </p>
        <div className="buttonContainer max-md:flex-col">
          <div
            style={{
              position: "relative",
              fontSize: "20px",
              fontWeight: 600,
            }}
          >
            <button
              className="secondaryButton bg-transparent text-white hover:bg-white hover:text-black"
              onClick={handleClick}
              data-tooltip-id="my-tooltip-inline"
              data-tooltip-content={tooltipContent}
            >
              Share
            </button>
            <Tooltip
              id="my-tooltip-inline"
              style={{ backgroundColor: "#fff", color: "black" }}
            />
          </div>
          <Link to="/" aria-current="page">
            <button className="secondaryButton">Close</button>
          </Link>
        </div>
      </div>
    </section>
  );
};
export default Fail;
