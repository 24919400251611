import React, { Suspense } from "react";
// import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";

import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";

import "assets/styles/app.scss";
import "tailwindcss/tailwind.css";

import Layout from "hoc/layout/layout";
import Spinner from "shared/components/spinner/spinner";
// import { State } from "shared/interface/state";
import "../src/index.css";
// import Login from "features/Login/login";
import Home from "features/Home/Home";

// const Home = lazy(() => import("../src/features/Home/Home"));

const App: React.FC = () => {
  // const { auth } = useSelector((state: State) => state.auth);

  if (true) {
    return (
      <Layout>
        <Suspense fallback={<Spinner />}>
          <Routes>
            {/* <Route path="/" element={<Home name="Aryan" />} /> */}
            {/* Redirect user to home page if route not found */}
            <Route path="*" element={<Navigate to="/" />} />
            <Route path="/" element={<Home />} />
          </Routes>
        </Suspense>
      </Layout>
    );
  }

  return (
    <Layout>
      <Suspense fallback={<Spinner />}>
        <Routes>
          {/* <Route
            path="/login"
            element={gin text="This is best thing i can hear for" />}
          /> */}
          {/* Redirect user to main route if route not found */}
          <Route
            path="*"
            element={<Navigate to="/profile/interaction/login" />}
          />
        </Routes>
      </Suspense>
    </Layout>
  );
};

export default App;
