export const formatTime = (seconds: any) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
};
export const getTodayDate = () => {
  return new Date().toISOString().substring(0, 10);
};

export const getCurrentHour = () => {
  return new Date().getHours();
};
