import Complete from "features/Complete/Complete";
import Fail from "features/Fail/Fail";
import Solve from "features/Solve/Solve";
import { useCallback, useEffect } from "react";
import { GameResultStateTypeLocalStorage } from "shared/interface/game";
import { GAME_SESSION_KEY } from "../../shared/constants/constant";
import useLocalStorage from "../../shared/hooks/useLocalStorage";
import {
  canGenerateNewGame,
  generateGameSession,
} from "../../shared/util/game";

const GameWidget = () => {
  const [lastGameState, setLastGameState] =
    useLocalStorage<GameResultStateTypeLocalStorage>(GAME_SESSION_KEY);
  console.log(lastGameState);

  const onGameStateChange = useCallback(
    (value: GameResultStateTypeLocalStorage) => {
      setLastGameState((prevValue) => {
        return {
          ...prevValue,
          ...value,
        };
      });
    },
    [setLastGameState]
  );

  useEffect(() => {
    if (canGenerateNewGame(lastGameState)) {
      const newGameSession = generateGameSession();
      console.info("New game session created", newGameSession);
      setLastGameState(newGameSession);
    }
  }, [lastGameState]);

  return (
    <>
      {lastGameState && lastGameState?.status === "pending" && (
        <Solve
          gameResultState={lastGameState}
          setGameResultState={onGameStateChange}
        />
      )}
      {lastGameState &&
        lastGameState?.status === "done" &&
        lastGameState.isWin && <Complete gameResultState={lastGameState} />}
      {lastGameState &&
        lastGameState?.status === "done" &&
        !lastGameState.isWin && <Fail gameResultState={lastGameState} />}
    </>
  );
};

export default GameWidget;
