import { useState } from "react";
import { getCurrentHour, getTodayDate } from "../util/date";
import { GAME_SESSION_KEY } from "../constants/constant";

// Define the hook with generics
function useLocalStorage<T>(
  key: string,
  defaultValue?: T
): [T, (valueOrFn: T | ((val: T) => T)) => void] {
  // Initialize the state with a function to handle initial value retrieval or setup
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = localStorage.getItem(key);
      // Return the parsed item or defaultValue
      let parsedData = item ? JSON.parse(item) : defaultValue;

      if (
        parsedData &&
        key === GAME_SESSION_KEY &&
        parsedData?.date === getTodayDate() &&
        parsedData?.hour !== getCurrentHour()
      ) {
        return defaultValue;
      }

      return parsedData;
    } catch (error) {
      console.error("Error reading localStorage key", key, ":", error);
      return defaultValue;
    }
  });

  // Define a function to update local storage and the state
  const setValue = (valueOrFn: T | ((val: T) => T)) => {
    // Determine the value based on whether valueOrFn is a function
    const valueToStore =
      valueOrFn instanceof Function ? valueOrFn(storedValue) : valueOrFn;
    setStoredValue(valueToStore);
    localStorage.setItem(key, JSON.stringify(valueToStore));
  };

  return [storedValue, setValue];
}

export default useLocalStorage;
