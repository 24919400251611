import { CharacterBoxProps, PositionType } from "../../shared/interface/game";
import { useEffect, useState } from "react";
import upArrowIcon from "../../assets/svg/upArreowIcon.svg";
import downArrowIcon from "../../assets/svg/downArrowIcon.svg";

export const CharacterBox = ({
  groupId,
  showHighlight,
  rightCharacter,
  running,
  groupAlphabets,
  selectedCharacter,
  onChangeArrow,
}: CharacterBoxProps) => {
  const [currentIndex, setCurrentIndex] = useState<number>(-1);

  useEffect(() => {
    const foundIndex = groupAlphabets.findIndex(
      (value) => selectedCharacter === value
    );
    setCurrentIndex(foundIndex);
  }, [selectedCharacter, groupAlphabets]);

  const updateIndexAndTriggerChange = (
    newIndex: number,
    positionType: PositionType
  ) => {
    setCurrentIndex((prevIndex) => {
      return newIndex;
    });
    onChangeArrow({
      groupId,
      newIndex,
      positionType,
    });
  };

  const handleUpArrowClick = () => {
    if (!running) return;
    const newIndex = currentIndex + 1 > 11 ? 0 : currentIndex + 1;
    updateIndexAndTriggerChange(newIndex, PositionType.Up);
    return newIndex;
  };

  const handleDownArrowClick = () => {
    if (!running) return;
    const newIndex = currentIndex - 1 < 0 ? 11 : currentIndex - 1;
    updateIndexAndTriggerChange(newIndex, PositionType.Down);
    return newIndex;
  };

  const isMatched = groupAlphabets?.[currentIndex] === rightCharacter;
  let borderColor = showHighlight
    ? isMatched
      ? "#8CED13"
      : "#FB5656"
    : "#F6E36E";
  borderColor = !running ? "#F6E36E" : borderColor;

  return (
    <div className="boxWrapper">
      <div
        onClick={handleUpArrowClick}
        className={`solveArrow ${
          !running ? "bg-lightBlue hover:bg-lightBlue" : ""
        }`}
      >
        <img className="arrowIcon" src={upArrowIcon} alt="Up Arrow Icon" />
      </div>
      <div className="beginBox" style={{ border: `7px solid ${borderColor}` }}>
        {groupAlphabets?.[currentIndex]}
      </div>
      <div
        onClick={handleDownArrowClick}
        className={`solveArrow ${
          !running ? "bg-lightBlue hover:bg-lightBlue" : ""
        }`}
      >
        <img className="arrowIcon" src={downArrowIcon} alt="Down Arrow Icon" />
      </div>
    </div>
  );
};
