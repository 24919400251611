import { useNavigate } from "react-router-dom";
import homePuzzleLogo from "../../assets/image/homeCypher.png";
import puzzleText from "../../assets/svg/homeLogoText.svg";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { useEffect, useRef, useState } from "react";
import useLocalStorage from "../../shared/hooks/useLocalStorage";
import { GameResultStateTypeLocalStorage } from "../../shared/interface/game";
import { APP_NAME, GAME_SESSION_KEY } from "../../shared/constants/constant";

import {
  getRemainingMinutes,
  generateGameSession,
} from "../../shared/util/game";

const TodayGameAlreadyCompletedScreen = (props: {
  gameResultState: GameResultStateTypeLocalStorage;
  setGameResultState: (value: GameResultStateTypeLocalStorage) => void;
}) => {
  const [tooltipContent, setTooltipContent] = useState("Copy to clipboard");
  const [remainingMinutes, setRemainingMinutues] = useState(
    getRemainingMinutes()
  );
  const interValRef: any = useRef(null);

  const handleClick = () => {
    navigator.clipboard
      .writeText(APP_NAME)
      .then(() => {
        setTooltipContent("Copied");
        setTimeout(() => {
          setTooltipContent("Copy to clipboard");
        }, 2000);
      })
      .catch((error) => {
        console.error("Failed to copy:", error);
      });
  };

  useEffect(() => {
    clearInterval(interValRef.current);
    interValRef.current = setInterval(() => {
      const remMin = getRemainingMinutes();
      if (remMin === "00:60")
        props.setGameResultState({
          ...props.gameResultState,
          ...generateGameSession(),
        });
      setRemainingMinutues(getRemainingMinutes());
    }, 60000);
    return () => {
      clearInterval(interValRef.current);
      interValRef.current = null;
    };
  }, []);

  return (
    <section className="screenContainer screenHeight">
      <div className="headerContainer">
        <Link to="/play" aria-current="page">
          <p className="headerPlay">How to play</p>
        </Link>
        <img className="homePuzzle" src={homePuzzleLogo} alt="homePuzzleLogo" />

        <h1 className="overTitle">Hey CypherSolver!</h1>

        <p className="overDescription">
          <span className="">
            You solved this hour’s puzzle come back in {remainingMinutes}
            &nbsp;minutes for the new one!
          </span>
        </p>
        <div className="buttonContainer">
          <div
            style={{
              position: "relative",
              fontSize: "20px",
              fontWeight: 600,
            }}
          >
            <button
              className="primaryButton"
              onClick={handleClick}
              data-tooltip-id="my-tooltip-inline1"
              data-tooltip-content={tooltipContent}
            >
              Share
            </button>
            <Tooltip
              id="my-tooltip-inline1"
              place="bottom"
              style={{ backgroundColor: "#fff", color: "black" }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

const TodayGameAvailableScreen = () => {
  let navigate = useNavigate();
  const [tooltipContent, setTooltipContent] = useState("Copy to clipboard");

  const routeChange = () => {
    let path = `/play`;
    navigate(path);
  };

  const handleClick = () => {
    navigator.clipboard
      .writeText(APP_NAME)
      .then(() => {
        setTooltipContent("Copied");
        setTimeout(() => {}, 2000);
      })
      .catch((error) => {
        console.error("Failed to copy:", error);
      });
  };

  return (
    <section className="screenContainer screenHeight">
      <div className="headerContainer">
        <Link to="/play" aria-current="page">
          <p className="headerPlay">How to play</p>
        </Link>
        <img className="homePuzzle" src={homePuzzleLogo} alt="homePuzzleLogo" />
        <img src={puzzleText} alt="puzzleText" />
        <p className="homeSubtitle">Solve the cypher in under 1 minute</p>
        <div className="buttonContainer">
          <div
            style={{
              position: "relative",
              fontSize: "20px",
              fontWeight: 600,
            }}
          >
            <button
              className="transparentButton"
              onClick={handleClick}
              data-tooltip-id="my-tooltip-inline2"
              data-tooltip-content={tooltipContent}
            >
              Share
            </button>
            <Tooltip
              id="my-tooltip-inline2"
              place="bottom"
              style={{ backgroundColor: "#fff", color: "black" }}
            />
          </div>
          <button className="primaryButton" onClick={routeChange}>
            Solve
          </button>
        </div>
      </div>
    </section>
  );
};

const Home = () => {
  const [lastGameState, setGameResultState] =
    useLocalStorage<GameResultStateTypeLocalStorage>(GAME_SESSION_KEY);

  return (
    <>
      {(!lastGameState ||
        (lastGameState && lastGameState.status === "pending")) && (
        <TodayGameAvailableScreen />
      )}
      {lastGameState && lastGameState.status === "done" && (
        <TodayGameAlreadyCompletedScreen
          gameResultState={lastGameState}
          setGameResultState={setGameResultState}
        />
      )}
    </>
  );
};
export default Home;
