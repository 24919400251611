import { useNavigate } from "react-router-dom";
import homePuzzleLogo from "../../assets/image/homeCypher.png";
// import { Link } from "react-router-dom";
import { useState } from "react";
import continueWeb from "../../assets/image/continueWeb.png";
import continueResponsive from "../../assets/image/continueResponsive.png";
import continueHello from "../../assets/image/continueHello.png";
import mobileHello from "../../assets/image/responsiveHello.png";

const InfoScreen = () => {
  const navigate = useNavigate();
  const routeChange = () => {
    const path = `/play-game`;
    navigate(path);
  };
  return (
    <div className="screenContainerPlay screenHeightHub">
      <div className="headerContainer">
        <p className="continueWrapper max-w-[427px]  py-10 max-md:pt-6 max-md:pb-5 continueResponsive">
          Use the word bank at the top, and try to solve the word cypher. When
          you press Submit, it will check your result... if incorrect you’ll see
        </p>
        <img className="max-md:hidden" src={continueWeb} alt="continueWeb" />
        <img
          className="md:hidden"
          src={continueResponsive}
          alt="continueResponsive"
        />
        <p className="continueWrapper pt-7 pb-8 max-md:pt-5 max-md:pb-4 continueResponsive">
          If you have successfully guessed the word of the hour, the puzzle will
          show this
        </p>
        <img
          className="max-md:hidden"
          src={continueHello}
          alt="continueHello"
        />
        <img className="md:hidden" src={mobileHello} alt="mobileHello" />
        <button className="primaryButton font-bold my-8" onClick={routeChange}>
          Solve
        </button>
      </div>
    </div>
  );
};

const Play = () => {
  const [showContinue, setShowContinue] = useState(false);
  const showContinueHandle = () => {
    setShowContinue(true);
  };
  return (
    <>
      {!showContinue && (
        <section className="screenContainer screenHeight">
          <div className="headerContainer">
            {/* <Link to="/play" aria-current="page">
              <p className="headerPlay">How to play</p>
            </Link> */}
            <img
              className="pb-tiny max-md:mt-xl max-xs:mt-[2.375rem]"
              src={homePuzzleLogo}
              alt="homePuzzleLogo"
            />
            <h1 className="text-mediumSmall leading-10 font-normal text-white">
              How to Play
            </h1>
            <p className="text-white  text-center tracking-tightest max-w-2xl font-normal text-largeSm leading-8 max-sm:text-lg pb-11 pt-6">
              Click the up and down arrows to cycle through letters, when you
              think you’ve got the word of the hour, hit Submit
            </p>
            <div className="text-largeXl text-center tracking-tightest pb-11 max-md:pb-tiny max-md:text-xl font-normal text-white">
              You have <span className=""> 1 minute...</span>
            </div>
            <button className="primaryButton" onClick={showContinueHandle}>
              Next
            </button>
          </div>
        </section>
      )}
      {showContinue && <InfoScreen />}
    </>
  );
};
export default Play;
