import React, { useEffect, useState } from "react";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import WithErrorHandler from "hoc/withErrorHandler/withErrorHandler";
import i18n from "shared/util/localization";
import ErrorBoundary from "shared/components/errorBoundary/errorBoundary";
import Navbar from "features/Navbar/Navbar";
import Home from "features/Home/Home";
import Play from "features/Play/Play";
import GameWidget from "features/GameWidget/GameWidget";

const Root: React.FC = (props) => {
  const [portrait, setPortrait] = useState<boolean>(true);
  const handleOrientationChange = () => {
    const isPortrait = window.matchMedia("(orientation: portrait)").matches;
    if (isPortrait) {
      setPortrait(true);
    } else {
      setPortrait(false);
    }
  };

  useEffect(() => {
    window.addEventListener("orientationchange", handleOrientationChange);
    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);
  return (
    <>
      {portrait ? (
        <div className="orientation-wrapper">
          <I18nextProvider i18n={i18n}>
            <WithErrorHandler />
            <ErrorBoundary>
              <div className="app-main">
                <BrowserRouter>
                  <App {...props} />
                  <Navbar />
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/play-game" element={<GameWidget />} />
                    <Route path="/play" element={<Play />} />
                  </Routes>
                </BrowserRouter>
              </div>
            </ErrorBoundary>
          </I18nextProvider>
        </div>
      ) : (
        <div className="orientation max-w-screen-2xl m-auto flex justify-center items-center h-screen">
          <p className="text-2xl font-medium flex justify-center items-center text-center max-w-sm  bg-black text-white">
            Application does not support landscape mode
          </p>
        </div>
      )}
    </>
  );
};

export default Root;
