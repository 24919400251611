import { getCurrentHour, getTodayDate } from "./date";
import { GameResultStateTypeLocalStorage } from "../interface/game";
import { MONTH_WISE_CHARACTER_FILES } from "assets/words/all-files";

const alphabet = "abcdefghijklmnopqrstuvwxyz".toUpperCase();

// const GAMES_DATA: {
//   [index: string]: string;
// } = {
//   "2024-04-15": "zesty",
//   "2024-04-16": "hello",
//   "2024-04-17": "verve",
//   "2024-04-18": "flair",
//   "2024-04-19": "pulse",
//   "2024-04-20": "magic",
//   "2024-04-21": "swift",
//   "2024-04-22": "pivot",
//   "2024-04-23": "focus",
//   "2024-04-24": "spark",
//   "2024-04-25": "tabgo",
//   "2024-04-26": "adhuc",
//   "2024-04-27": "velut",
//   "2024-04-28": "verto",
//   "2024-04-29": "trans",
//   "2024-04-30": "teneo",
//   "2024-05-01": "demum",
//   "2024-05-02": "timor",
//   "2024-05-03": "peior",
//   "2024-05-04": "volup",
//   "2024-05-05": "usque",
//   "2024-05-06": "coepi",
//   "2024-05-07": "timor",
//   "2024-05-08": "harum",
//   "2024-05-09": "saepe",
//   "2024-05-10": "summa",
//   "2024-05-11": "certe",
//   "2024-05-12": "ultra",
//   "2024-05-13": "adsum",
//   "2024-05-14": "caste",
//   "2024-05-15": "paens",
//   "2024-05-16": "rerum",
//   "2024-05-17": "cunae",
//   "2024-05-18": "amita",
//   "2024-05-19": "solum",
//   "2024-05-20": "creta",
//   "2024-05-21": "cibus",
//   "2024-05-22": "fugit",
//   "2024-05-23": "antea",
//   "2024-05-24": "vulgo",
//   "2024-05-25": "solvo",
//   "2024-05-26": "amita",
//   "2024-05-27": "rerum",
//   "2024-05-28": "infit",
//   "2024-05-29": "vulgo",
//   "2024-05-30": "turba",
//   "2024-05-31": "denuo",
//   "2024-06-01": "curvo",
//   "2024-06-02": "capto",
//   "2024-06-03": "uredo",
//   "2024-06-04": "volva",
//   "2024-06-05": "voveo",
//   "2024-06-06": "pecto",
//   "2024-06-07": "clamo",
//   "2024-06-08": "talus",
//   "2024-06-09": "super",
//   "2024-06-10": "terga",
//   "2024-06-11": "vetus",
//   "2024-06-12": "certe",
//   "2024-06-13": "velut",
//   "2024-06-14": "causa",
//   "2024-06-15": "audeo",
//   "2024-06-16": "video",
//   "2024-06-17": "taceo",
//   "2024-06-18": "timor",
//   "2024-06-19": "totam",
//   "2024-06-20": "turbo",
//   "2024-06-21": "demum",
//   "2024-06-22": "sulum",
//   "2024-06-23": "ullus",
//   "2024-06-24": "volva",
//   "2024-06-25": "talus",
//   "2024-06-26": "ipsam",
//   "2024-06-27": "caste",
//   "2024-06-28": "rerum",
//   "2024-06-29": "denuo",
//   "2024-06-30": "comis",
//   "2024-06-31": "cunae",
//   "2024-07-01": "terra",
//   "2024-07-02": "tremo",
//   "2024-07-03": "debeo",
//   "2024-07-04": "earum",
//   "2024-07-05": "super",
//   "2024-07-06": "soleo",
//   "2024-07-07": "cornu",
//   "2024-07-08": "coepi",
//   "2024-07-09": "velut",
//   "2024-07-10": "tabgo",
//   "2024-07-11": "tollo",
//   "2024-07-12": "trans",
//   "2024-07-13": "eaque",
//   "2024-07-14": "alter",
//   "2024-07-15": "cavus",
//   "2024-07-16": "ipsum",
//   "2024-07-17": "arguo",
//   "2024-07-18": "vobis",
//   "2024-07-19": "adhuc",
//   "2024-07-20": "caput",
//   "2024-07-21": "acies",
//   "2024-07-22": "carus",
//   "2024-07-23": "celer",
//   "2024-07-24": "causa",
//   "2024-07-25": "barba",
//   "2024-07-26": "causa",
//   "2024-07-27": "altus",
//   "2024-07-28": "caste",
//   "2024-07-29": "porro",
//   "2024-07-30": "turba",
//   "2024-07-31": "caveo",
//   "2024-08-01": "aurum",
//   "2024-08-02": "aveho",
//   "2024-08-03": "culpo",
//   "2024-08-04": "voveo",
//   "2024-08-05": "demum",
//   "2024-08-06": "barba",
//   "2024-08-07": "aptus",
//   "2024-08-08": "canto",
//   "2024-08-09": "rerum",
//   "2024-08-10": "totus",
//   "2024-08-11": "terga",
//   "2024-08-12": "vergo",
//   "2024-08-13": "ullam",
//   "2024-08-14": "audio",
//   "2024-08-15": "certe",
//   "2024-08-16": "votum",
//   "2024-08-17": "valde",
//   "2024-08-18": "volup",
//   "2024-08-19": "ascit",
//   "2024-08-20": "venia",
//   "2024-08-21": "carus",
//   "2024-08-22": "causa",
//   "2024-08-23": "turbo",
//   "2024-08-24": "capio",
//   "2024-08-25": "vigor",
//   "2024-08-26": "arbor",
//   "2024-08-27": "valde",
//   "2024-08-28": "debeo",
//   "2024-08-29": "cavus",
//   "2024-08-30": "asper",
//   "2024-08-31": "nobis",
//   "2024-09-01": "capio",
//   "2024-09-02": "virga",
//   "2024-09-03": "volup",
//   "2024-09-04": "acies",
//   "2024-09-05": "pecus",
//   "2024-09-06": "creta",
//   "2024-09-07": "verus",
//   "2024-09-08": "error",
//   "2024-09-09": "arcus",
//   "2024-09-10": "tendo",
//   "2024-09-11": "curis",
//   "2024-09-12": "venio",
//   "2024-09-13": "certe",
//   "2024-09-14": "absum",
//   "2024-09-15": "comis",
//   "2024-09-16": "autus",
//   "2024-09-17": "trado",
//   "2024-09-18": "adamo",
//   "2024-09-19": "ultio",
//   "2024-09-20": "tenax",
//   "2024-09-21": "careo",
//   "2024-09-22": "vetus",
//   "2024-09-23": "pecto",
//   "2024-09-24": "caute",
//   "2024-09-25": "vobis",
//   "2024-09-26": "totus",
//   "2024-09-27": "audio",
//   "2024-09-28": "peior",
//   "2024-09-29": "vergo",
//   "2024-09-30": "carbo",
//   "2024-10-01": "venio",
//   "2024-10-02": "aetas",
//   "2024-10-03": "audax",
//   "2024-10-04": "tabgo",
//   "2024-10-05": "verto",
//   "2024-10-06": "capio",
//   "2024-10-07": "alter",
//   "2024-10-08": "celer",
//   "2024-10-09": "terga",
//   "2024-10-10": "cibus",
//   "2024-10-11": "deleo",
//   "2024-10-12": "arcus",
//   "2024-10-13": "neque",
//   "2024-10-14": "sequi",
//   "2024-10-15": "capto",
//   "2024-10-16": "solum",
//   "2024-10-17": "rerum",
//   "2024-10-18": "audeo",
//   "2024-10-19": "annus",
//   "2024-10-20": "uredo",
//   "2024-10-21": "thema",
//   "2024-10-22": "velut",
//   "2024-10-23": "iusto",
//   "2024-10-24": "valde",
//   "2024-10-25": "adnuo",
//   "2024-10-26": "barba",
//   "2024-10-27": "viduo",
//   "2024-10-28": "dicta",
//   "2024-10-29": "annus",
//   "2024-10-30": "autus",
//   "2024-10-31": "causa",
//   "2024-11-01": "vetus",
//   "2024-11-02": "causa",
//   "2024-11-03": "talis",
//   "2024-11-04": "ipsum",
//   "2024-11-05": "aiunt",
//   "2024-11-06": "cunae",
//   "2024-11-07": "natus",
//   "2024-11-08": "audio",
//   "2024-11-09": "audio",
//   "2024-11-10": "curto",
//   "2024-11-11": "color",
//   "2024-11-12": "vinco",
//   "2024-11-13": "viduo",
//   "2024-11-14": "totam",
//   "2024-11-15": "capio",
//   "2024-11-16": "virga",
//   "2024-11-17": "talio",
//   "2024-11-18": "nihil",
//   "2024-11-19": "tabgo",
//   "2024-11-20": "natus",
//   "2024-11-21": "casus",
//   "2024-11-22": "totam",
//   "2024-11-23": "velit",
//   "2024-11-24": "vesco",
//   "2024-11-25": "verto",
//   "2024-11-26": "audio",
//   "2024-11-27": "cunae",
//   "2024-11-28": "curia",
//   "2024-11-29": "carpo",
//   "2024-11-30": "vigor",
//   "2024-12-01": "infit",
//   "2024-12-02": "sulum",
//   "2024-12-03": "dolor",
//   "2024-12-04": "debeo",
//   "2024-12-05": "tremo",
//   "2024-12-06": "cerno",
//   "2024-12-07": "tenax",
//   "2024-12-08": "creta",
//   "2024-12-09": "debeo",
//   "2024-12-10": "decet",
//   "2024-12-11": "valde",
//   "2024-12-12": "celer",
//   "2024-12-13": "ascit",
//   "2024-12-14": "tenax",
//   "2024-12-15": "solus",
//   "2024-12-16": "atrox",
//   "2024-12-17": "cupio",
//   "2024-12-18": "aduro",
//   "2024-12-19": "quasi",
//   "2024-12-20": "comis",
//   "2024-12-21": "atrox",
//   "2024-12-22": "error",
//   "2024-12-23": "annus",
//   "2024-12-24": "curso",
//   "2024-12-25": "animi",
//   "2024-12-26": "soleo",
//   "2024-12-27": "sopor",
//   "2024-12-28": "trado",
//   "2024-12-29": "abbas",
//   "2024-12-30": "caute",
//   "2024-12-31": "barba",
//   "2025-01-01": "clamo",
//   "2025-01-02": "vilis",
//   "2025-01-03": "coepi",
//   "2025-01-04": "autus",
//   "2025-01-05": "cibus",
//   "2025-01-06": "ullam",
//   "2025-01-07": "valde",
//   "2025-01-08": "cavus",
//   "2025-01-09": "dolor",
//   "2025-01-10": "curia",
//   "2025-01-11": "atque",
//   "2025-01-12": "teres",
//   "2025-01-13": "totam",
//   "2025-01-14": "talio",
//   "2025-01-15": "pecto",
//   "2025-01-16": "vulgo",
//   "2025-01-17": "creta",
//   "2025-01-18": "alias",
//   "2025-01-19": "cavus",
//   "2025-01-20": "atque",
//   "2025-01-21": "optio",
//   "2025-01-22": "conor",
//   "2025-01-23": "adhuc",
//   "2025-01-24": "sequi",
//   "2025-01-25": "caste",
//   "2025-01-26": "sequi",
//   "2025-01-27": "surgo",
//   "2025-01-28": "arbor",
//   "2025-01-29": "villa",
//   "2025-01-30": "eaque",
//   "2025-01-31": "claro",
//   "2025-02-01": "altus",
//   "2025-02-02": "natus",
//   "2025-02-03": "solum",
//   "2025-02-04": "autem",
//   "2025-02-05": "verus",
//   "2025-02-06": "rerum",
//   "2025-02-07": "aurum",
//   "2025-02-08": "utrum",
//   "2025-02-09": "ascit",
//   "2025-02-10": "tabgo",
//   "2025-02-11": "altus",
//   "2025-02-12": "certe",
//   "2025-02-13": "teneo",
//   "2025-02-14": "venia",
//   "2025-02-15": "atqui",
//   "2025-02-16": "soleo",
//   "2025-02-17": "peior",
//   "2025-02-18": "solus",
//   "2025-02-19": "uredo",
//   "2025-02-20": "aduro",
//   "2025-02-21": "omnis",
//   "2025-02-22": "aptus",
//   "2025-02-23": "solio",
//   "2025-02-24": "vulgo",
//   "2025-02-25": "ipsam",
//   "2025-02-26": "coepi",
//   "2025-02-27": "aurum",
//   "2025-02-28": "vesco",
//   "2025-03-01": "tamen",
//   "2025-03-02": "tutis",
//   "2025-03-03": "taceo",
//   "2025-03-04": "spero",
//   "2025-03-05": "ultra",
//   "2025-03-06": "omnis",
//   "2025-03-07": "vomer",
//   "2025-03-08": "solum",
//   "2025-03-09": "cauda",
//   "2025-03-10": "amita",
//   "2025-03-11": "eaque",
//   "2025-03-12": "natus",
//   "2025-03-13": "timor",
//   "2025-03-14": "alius",
//   "2025-03-15": "debeo",
//   "2025-03-16": "vilis",
//   "2025-03-17": "alter",
//   "2025-03-18": "nulla",
//   "2025-03-19": "volva",
//   "2025-03-20": "casus",
//   "2025-03-21": "cinis",
//   "2025-03-22": "voveo",
//   "2025-03-23": "caveo",
//   "2025-03-24": "cinis",
//   "2025-03-25": "pecco",
//   "2025-03-26": "coepi",
//   "2025-03-27": "adamo",
//   "2025-03-28": "ipsum",
//   "2025-03-29": "amita",
//   "2025-03-30": "pecto",
//   "2025-03-31": "causa",
//   "2025-04-01": "caute",
//   "2025-04-02": "verto",
//   "2025-04-03": "saepe",
//   "2025-04-04": "cinis",
//   "2025-04-05": "vobis",
//   "2025-04-06": "neque",
//   "2025-04-07": "teneo",
//   "2025-04-08": "aeger",
//   "2025-04-09": "nulla",
//   "2025-04-10": "aurum",
//   "2025-04-11": "villa",
//   "2025-04-12": "comis",
//   "2025-04-13": "aveho",
//   "2025-04-14": "conor",
//   "2025-04-15": "creta",
//   "2025-04-16": "voveo",
//   "2025-04-17": "ascit",
//   "2025-04-18": "vesco",
//   "2025-04-19": "causa",
//   "2025-04-20": "aptus",
//   "2025-04-21": "cupio",
//   "2025-04-22": "volup",
//   "2025-04-23": "vomer",
//   "2025-04-24": "atqui",
//   "2025-04-25": "carbo",
//   "2025-04-26": "coepi",
//   "2025-04-27": "supra",
//   "2025-04-28": "animi",
//   "2025-04-29": "villa",
//   "2025-04-30": "audax",
//   "2025-05-01": "decet",
//   "2025-05-02": "vomer",
//   "2025-05-03": "curvo",
//   "2025-05-04": "comis",
//   "2025-05-05": "autus",
//   "2025-05-06": "verto",
//   "2025-05-07": "asper",
//   "2025-05-08": "earum",
//   "2025-05-09": "tenax",
//   "2025-05-10": "copia",
//   "2025-05-11": "velum",
//   "2025-05-12": "magni",
//   "2025-05-13": "fugit",
//   "2025-05-14": "volva",
//   "2025-05-15": "turba",
//   "2025-05-16": "talis",
//   "2025-05-17": "audax",
//   "2025-05-18": "usque",
//   "2025-05-19": "aptus",
//   "2025-05-20": "vorax",
//   "2025-05-21": "causa",
//   "2025-05-22": "cupio",
//   "2025-05-23": "totam",
//   "2025-05-24": "minus",
//   "2025-05-25": "civis",
//   "2025-05-26": "solvo",
//   "2025-05-27": "viduo",
//   "2025-05-28": "optio",
//   "2025-05-29": "culpa",
//   "2025-05-30": "paens",
//   "2025-05-31": "cupio",
//   "2025-06-01": "comis",
//   "2025-06-02": "caste",
//   "2025-06-03": "asper",
//   "2025-06-04": "alias",
//   "2025-06-05": "quasi",
//   "2025-06-06": "sopor",
//   "2025-06-07": "usque",
//   "2025-06-08": "talis",
//   "2025-06-09": "talio",
//   "2025-06-10": "spero",
//   "2025-06-11": "natus",
//   "2025-06-12": "verto",
//   "2025-06-13": "paens",
//   "2025-06-14": "ultio",
//   "2025-06-15": "virgo",
//   "2025-06-16": "atqui",
//   "2025-06-17": "canto",
//   "2025-06-18": "fugit",
//   "2025-06-19": "harum",
//   "2025-06-20": "curia",
//   "2025-06-21": "thema",
//   "2025-06-22": "adnuo",
//   "2025-06-23": "vetus",
//   "2025-06-24": "vorax",
//   "2025-06-25": "asper",
//   "2025-06-26": "iusto",
//   "2025-06-27": "cauda",
//   "2025-06-28": "vilis",
//   "2025-06-29": "caute",
//   "2025-06-30": "virga",
//   "2025-06-31": "cunae",
//   "2025-07-01": "valde",
//   "2025-07-02": "earum",
//   "2025-07-03": "decor",
//   "2025-07-04": "sopor",
//   "2025-07-05": "natus",
//   "2025-07-06": "turbo",
//   "2025-07-07": "vetus",
//   "2025-07-08": "certe",
//   "2025-07-09": "totam",
//   "2025-07-10": "credo",
//   "2025-07-11": "ultio",
//   "2025-07-12": "saepe",
//   "2025-07-13": "curso",
//   "2025-07-14": "atqui",
//   "2025-07-15": "velut",
//   "2025-07-16": "comis",
//   "2025-07-17": "tollo",
//   "2025-07-18": "solus",
//   "2025-07-19": "nobis",
//   "2025-07-20": "acies",
//   "2025-07-21": "tollo",
//   "2025-07-22": "canis",
//   "2025-07-23": "cavus",
//   "2025-07-24": "earum",
//   "2025-07-25": "tendo",
//   "2025-07-26": "autem",
//   "2025-07-27": "surgo",
//   "2025-07-28": "civis",
//   "2025-07-29": "peior",
//   "2025-07-30": "peior",
//   "2025-07-31": "fugit",
//   "2025-08-01": "tenus",
//   "2025-08-02": "curvo",
//   "2025-08-03": "sulum",
//   "2025-08-04": "ultio",
//   "2025-08-05": "solum",
//   "2025-08-06": "carpo",
//   "2025-08-07": "curvo",
//   "2025-08-08": "nulla",
//   "2025-08-09": "saepe",
//   "2025-08-10": "tergo",
//   "2025-08-11": "comis",
//   "2025-08-12": "ullus",
//   "2025-08-13": "tergo",
//   "2025-08-14": "arguo",
//   "2025-08-15": "decet",
//   "2025-08-16": "pecto",
//   "2025-08-17": "theca",
//   "2025-08-18": "claro",
//   "2025-08-19": "comis",
//   "2025-08-20": "demum",
//   "2025-08-21": "iusto",
//   "2025-08-22": "certe",
//   "2025-08-23": "vitae",
//   "2025-08-24": "solus",
//   "2025-08-25": "ipsam",
//   "2025-08-26": "neque",
//   "2025-08-27": "video",
//   "2025-08-28": "cunae",
//   "2025-08-29": "traho",
//   "2025-08-30": "paens",
//   "2025-08-31": "atque",
//   "2025-09-01": "soleo",
//   "2025-09-02": "civis",
//   "2025-09-03": "deleo",
//   "2025-09-04": "altus",
//   "2025-09-05": "curso",
//   "2025-09-06": "barba",
//   "2025-09-07": "summa",
//   "2025-09-08": "pecus",
//   "2025-09-09": "dicta",
//   "2025-09-10": "voveo",
//   "2025-09-11": "ullam",
//   "2025-09-12": "cupio",
//   "2025-09-13": "aeger",
//   "2025-09-14": "aetas",
//   "2025-09-15": "arceo",
//   "2025-09-16": "uredo",
//   "2025-09-17": "pecus",
//   "2025-09-18": "canis",
//   "2025-09-19": "creta",
//   "2025-09-20": "magni",
//   "2025-09-21": "paens",
//   "2025-09-22": "audax",
//   "2025-09-23": "audeo",
//   "2025-09-24": "totus",
//   "2025-09-25": "autem",
//   "2025-09-26": "vesco",
//   "2025-09-27": "alter",
//   "2025-09-28": "iusto",
//   "2025-09-29": "audax",
//   "2025-09-30": "adsum",
//   "2025-10-01": "curia",
//   "2025-10-02": "capio",
//   "2025-10-03": "timor",
//   "2025-10-04": "infit",
//   "2025-10-05": "virga",
//   "2025-10-06": "dicta",
//   "2025-10-07": "anser",
//   "2025-10-08": "denuo",
//   "2025-10-09": "terga",
//   "2025-10-10": "solum",
//   "2025-10-11": "tener",
//   "2025-10-12": "ipsam",
//   "2025-10-13": "eaque",
//   "2025-10-14": "casus",
//   "2025-10-15": "pecco",
//   "2025-10-16": "culpa",
//   "2025-10-17": "caste",
//   "2025-10-18": "creta",
//   "2025-10-19": "eaque",
//   "2025-10-20": "velum",
//   "2025-10-21": "canis",
//   "2025-10-22": "ipsum",
//   "2025-10-23": "casso",
//   "2025-10-24": "deleo",
//   "2025-10-25": "turbo",
//   "2025-10-26": "votum",
//   "2025-10-27": "valeo",
//   "2025-10-28": "minus",
//   "2025-10-29": "teres",
//   "2025-10-30": "saepe",
//   "2025-10-31": "culpo",
//   "2025-11-01": "careo",
//   "2025-11-02": "decet",
//   "2025-11-03": "teneo",
//   "2025-11-04": "virgo",
//   "2025-11-05": "illum",
//   "2025-11-06": "porro",
//   "2025-11-07": "aiunt",
//   "2025-11-08": "volva",
//   "2025-11-09": "color",
//   "2025-11-10": "talus",
//   "2025-11-11": "vesco",
//   "2025-11-12": "aegre",
//   "2025-11-13": "error",
//   "2025-11-14": "video",
//   "2025-11-15": "solvo",
//   "2025-11-16": "verto",
//   "2025-11-17": "rerum",
//   "2025-11-18": "curto",
//   "2025-11-19": "ascit",
//   "2025-11-20": "optio",
//   "2025-11-21": "decor",
//   "2025-11-22": "debeo",
//   "2025-11-23": "culpo",
//   "2025-11-24": "annus",
//   "2025-11-25": "copia",
//   "2025-11-26": "error",
//   "2025-11-27": "tener",
//   "2025-11-28": "absum",
//   "2025-11-29": "autem",
//   "2025-11-30": "anser",
//   "2025-12-01": "curia",
//   "2025-12-02": "autem",
//   "2025-12-03": "quasi",
//   "2025-12-04": "theca",
//   "2025-12-05": "super",
//   "2025-12-06": "venia",
//   "2025-12-07": "fugit",
//   "2025-12-08": "vinum",
//   "2025-12-09": "soleo",
//   "2025-12-10": "amita",
//   "2025-12-11": "vobis",
//   "2025-12-12": "eaque",
//   "2025-12-13": "asper",
//   "2025-12-14": "totam",
//   "2025-12-15": "totam",
//   "2025-12-16": "tabgo",
//   "2025-12-17": "arceo",
//   "2025-12-18": "carpo",
//   "2025-12-19": "autem",
//   "2025-12-20": "surgo",
//   "2025-12-21": "causa",
//   "2025-12-22": "acies",
//   "2025-12-23": "aveho",
//   "2025-12-24": "sulum",
//   "2025-12-25": "arceo",
//   "2025-12-26": "animi",
//   "2025-12-27": "trans",
//   "2025-12-28": "cauda",
//   "2025-12-29": "curso",
//   "2025-12-30": "cinis",
//   "2025-12-31": "trado",
//   "2026-01-01": "vulgo",
//   "2026-01-02": "uredo",
//   "2026-01-03": "talis",
//   "2026-01-04": "antea",
//   "2026-01-05": "volup",
//   "2026-01-06": "tamen",
//   "2026-01-07": "viduo",
//   "2026-01-08": "ultio",
//   "2026-01-09": "harum",
//   "2026-01-10": "timor",
//   "2026-01-11": "carpo",
//   "2026-01-12": "sopor",
//   "2026-01-13": "vinum",
//   "2026-01-14": "timor",
//   "2026-01-15": "tutis",
//   "2026-01-16": "aiunt",
//   "2026-01-17": "ipsum",
//   "2026-01-18": "vitae",
//   "2026-01-19": "alter",
//   "2026-01-20": "adnuo",
//   "2026-01-21": "capto",
//   "2026-01-22": "utrum",
//   "2026-01-23": "capto",
//   "2026-01-24": "audax",
//   "2026-01-25": "adamo",
//   "2026-01-26": "debeo",
//   "2026-01-27": "asper",
//   "2026-01-28": "asper",
//   "2026-01-29": "tabgo",
//   "2026-01-30": "vobis",
//   "2026-01-31": "tener",
//   "2026-02-01": "tollo",
//   "2026-02-02": "vitae",
//   "2026-02-03": "minus",
//   "2026-02-04": "surgo",
//   "2026-02-05": "usque",
//   "2026-02-06": "carpo",
//   "2026-02-07": "talus",
//   "2026-02-08": "fugit",
//   "2026-02-09": "nulla",
//   "2026-02-10": "arbor",
//   "2026-02-11": "alter",
//   "2026-02-12": "conor",
//   "2026-02-13": "soleo",
//   "2026-02-14": "aegre",
//   "2026-02-15": "error",
// };

export const generateRandomAlphabets = (): string[] => {
  return Array.from(
    { length: 5 },
    () => alphabet[Math.floor(Math.random() * alphabet.length)]
  );
};

export const generateGameSession = () => {
  const winWord = getTodayGame();

  return {
    status: "pending",
    remainingTime: 60,
    date: getTodayDate(),
    hour: new Date().getHours(),
    isWin: false,
    activeWordChar: winWord,
    finishedWordChar: "",
  };
};

export const nextFiveCharacters = (
  arr: string[]
): {
  nextCharacters: string[][];
  randomSelection: string[];
} => {
  if (!arr.length) {
    return {
      nextCharacters: [],
      randomSelection: [],
    };
  }
  const characters = arr.map((str: string) => {
    if (!str) {
      return "";
    }
    return (
      str +
      str.replace(/[a-zA-Z]/g, (char) => {
        const base =
          char.toLowerCase() === char ? "a".charCodeAt(0) : "A".charCodeAt(0);
        return Array.from({ length: 12 }, (_, i) =>
          String.fromCharCode(((char.charCodeAt(0) - base + i + 1) % 26) + base)
        ).join("");
      })
    );
  });
  const data = [];
  const randomData = [];
  for (const item of characters) {
    const splittedValue = item.split("");
    const randomIndex = Math.floor(Math.random() * splittedValue.length);
    const selectedString = splittedValue[randomIndex];

    data.push(splittedValue);
    randomData.push(selectedString);
  }
  return { nextCharacters: data, randomSelection: randomData };
};

export const canGenerateNewGame = (
  lastGameState: GameResultStateTypeLocalStorage
) => {
  if (!lastGameState) {
    return true;
  }

  if (lastGameState.remainingTime < 0) {
    return true;
  }

  if (
    (lastGameState?.date === getTodayDate() &&
      lastGameState?.hour === getCurrentHour()) ||
    !lastGameState?.activeWordChar
  ) {
    return false;
  }

  return lastGameState.activeWordChar !== getTodayGame();
};

export const getTodayGame = () => {
  const currentDate = new Date().toISOString().substr(0, 10);
  const currentHour = new Date().getHours();
  currentDate.split("-").pop();
  const file_name = currentDate.split("-").splice(0, 2).join("-");
  const file = MONTH_WISE_CHARACTER_FILES[file_name];
  const formatCurentDate = currentDate.split("-").reverse().join("-"); //for date format mismatch
  const current_word = file[formatCurentDate][currentHour];
  return (current_word ?? "").toUpperCase();
};

export const generateRandomAlphabetList = () => {
  const array = alphabet.split("").slice(); // Create a copy of the array
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1)); // Random index from 0 to i
    [array[i], array[j]] = [array[j], array[i]]; // Swap elements
  }
  return array;
};

export type group = string[];
export const generateGroups = (chars: string[]): group[] => {
  const newGroups: group[] = chars.map((_) => generateRandomAlphabetList());
  return newGroups;
};

export const getRemainingMinutes = () => {
  const currentMinutue = new Date().getMinutes();
  const remMin = 60 - currentMinutue;
  return currentMinutue > 59
    ? "01:00"
    : `00:${remMin.toLocaleString().length <= 1 ? "0" : ""}${remMin}`;
};
