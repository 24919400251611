export enum PositionType {
  Up = "up",
  Down = "down",
}

export type SolvePropsType = {
  gameResultState: GameResultStateTypeLocalStorage;
  setGameResultState: (value: GameResultStateTypeLocalStorage) => void;
};

export type CharacterBoxProps = {
  groupId: number;
  showHighlight: boolean;
  rightCharacter: string;
  running: boolean;
  groupAlphabets: string[];
  selectedCharacter: string;
  onChangeArrow: (args: ChangeArrayOptions) => void;
};

export type ChangeArrayOptions = {
  groupId: number;
  positionType: PositionType;
  newIndex: number;
};

export type GameResultStateType = {
  remainingTime: number;
  finishedWordChar: string;
  activeWordChar: string;
  isWin: boolean;
};

export type GameResultStateTypeLocalStorage = GameResultStateType & {
  status: string;
  date: string;
  hour: number;
};
