import April from "./2024-04.json";
import May from "./2025-05.json";

export const MONTH_WISE_CHARACTER_FILES: { [key: string]: any } = {
  "2024-04": April,
  "2024-05": May,
};

export const RANDOM_WORDS = [
  "babas",
  "swear",
  "tweet",
  "crimp",
  "tread",
  "bozos",
  "tepas",
  "litas",
  "jouks",
  "arias",
  "defer",
  "round",
  "quoll",
  "moats",
  "hoist",
  "snows",
  "smack",
  "picky",
  "ivied",
  "virus",
  "drill",
  "reams",
  "mated",
  "heeze",
  "cramp",
  "rimer",
  "loops",
  "deash",
  "amens",
  "glume",
  "crack",
  "cooch",
  "scull",
  "skelm",
  "cutie",
  "alibi",
  "toxin",
  "bacon",
  "chore",
  "opium",
  "gapes",
  "tawie",
  "rebbe",
  "cates",
  "talas",
  "croze",
  "akees",
  "orpin",
  "cafes",
  "remet",
  "wagon",
  "porns",
  "moose",
  "idler",
  "feyer",
  "crave",
  "anyon",
  "barky",
  "jerid",
  "staff",
  "fakey",
  "warns",
  "sengi",
  "blaws",
  "media",
  "gammy",
  "leaks",
  "dilly",
  "brave",
  "teach",
  "tykes",
  "sites",
  "vogie",
  "moors",
  "atria",
  "fauve",
  "brees",
  "flout",
  "veils",
  "coden",
  "teffs",
  "runty",
  "nipas",
  "reedy",
  "adios",
  "alcid",
  "toffy",
  "hoers",
  "monde",
  "modal",
  "melon",
  "klutz",
  "elver",
  "looie",
  "serge",
  "havoc",
  "stoup",
  "spits",
  "bovid",
  "lithe",
];
